import React, { FC, PropsWithChildren } from 'react'
import Button from '@dashboard/components/Button'
import classNamesBind from 'classnames/bind'
import css from '../styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  action?: () => void
  to?: string
}

const Action: FC<PropsWithChildren<OwnProps>> = ({ action, to, children }) => {
  return (
    <div className={classNames('button')}>
      <Button actionBlue noMargin small onClick={action} href={to}>
        {children}
      </Button>
    </div>
  )
}

export default Action
