import React, { ChangeEvent, createRef } from 'react'
import classNamesBind from 'classnames/bind'
import * as ls from 'local-storage'
import { ParsedUrlQuery } from 'querystring'
import Router from 'next/router'
import Link from 'next/link'

import Alert from '@dashboard/components/Alert'
import DeepLinkFrame from '@dashboard/components/DeepLinkFrame'
import Input from '@dashboard/components/Input'
import InputGroup from '@dashboard/components/InputGroup'
import MarketingSection from '@dashboard/components/MarketingSection'
import Dropdown from '@dashboard/components/Dropdown'
import Header from '@dashboard/components/Dashboard/components/Header/HeaderLegacy'
import RegisterCallToAction from '@dashboard/components/RegisterCallToAction'
import LoadingBox from '@dashboard/components/LoadingBox'
import OnboardingCarousel from '@dashboard/components/OnboardingCarousel'
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3'
import { rsEvent, rsIdentify } from '@dashboard/lib/analytics'
import {
  LoadingStates,
  DropdownOption,
  SegmentTypes,
  FullAuthUser,
  GoogleCredentialType,
  TeamSimple,
} from '@dashboard/lib/types'
import { localAuthToken, handleTasks } from '@dashboard/lib/user'

import { TaskName, TaskOptions } from '@dashboard/lib/types'
import { CompanySuggestResponse } from '@dashboard/store/actions/company/suggest'
import { RegistrationParams } from '@dashboard/store/actions/user/registration'
import { getQueryParam } from '@dashboard/lib/browser'
import { GOOGLE_CLIENT_ID } from '@dashboard/lib/constants'
import jwtDecode from 'jwt-decode'
import Button from '@dashboard/components/Button'
import Checkbox from '@dashboard/components/Checkbox'

interface ClearbitCompany {
  name: string
  domain: string
  logo: string
}

export interface OwnProps {
  query: ParsedUrlQuery
  asPath: string
  gToken?: string
}

export interface StateProps {
  afterLoginPath: string | undefined
  justFinishedPubAssessment: boolean
  publicAssessment: boolean
  showMarketing: boolean
  uiState: LoadingStates
  suggestStates: Record<string, LoadingStates | undefined>
  suggestResults: Record<string, CompanySuggestResponse | undefined>
  registrationState: {
    state: LoadingStates
    message: string
    parameter: string
  }
  actions: string
  teamInviteToken?: string
  companyDomain?: string
  companyInviteToken?: string
  companyName?: string
  email?: string
  facebookID?: string
  firstName?: string
  inviteToken?: string
  lastName?: string
  profileId?: string
  actionsParam?: string
  authUser: FullAuthUser | null
  meState: LoadingStates
  teamSimpleState: LoadingStates
  teamSimpleResult: TeamSimple | null
}

export interface DispatchProps {
  registerAttributes: (attributes: RegistrationParams) => void
  suggestCompany: (domain: string) => void
  runTasks: (taskNames: TaskName[], options: TaskOptions) => void
  loadTeamSimple: (teamId: string) => void
  loadMe: () => void
}

interface State {
  suggestions: Array<ClearbitCompany>
  searchValue: string
  logo: string
  gender: string | null
  email: string
  emailError: boolean
  firstName: string
  lastName: string
  password: string
  confirmPassword: string
  companyName: string
  companyDomain: string
  passwordMismatch: boolean
  passwordTooShort: boolean
  genderMissing: boolean
  gToken: string | null
  showAlert: boolean
  rsEventFired: boolean
  successfullyAuthed: boolean
  companyWebsite: string
  checked: boolean
  recaptchaError: boolean
  recaptchaToken: string | null
  refreshReCaptcha: boolean
}

type Props = StateProps & DispatchProps & OwnProps

import css from './styles.module.scss'
import { validateEmail } from '@dashboard/lib/email'
const classNames = classNamesBind.bind(css)
const wsj = '/static/share/images/wsj.svg'
const cnn = '/static/share/images/cnn.svg'
const fortune = '/static/share/images/fortune.svg'
const guardian = '/static/share/images/guardian.svg'
const huffpost = '/static/share/images/huffpost.svg'
export const AUTH_QUERY_PARAMS_STORAGE_KEY = 'auth-query-params'

export default class Registration extends React.Component<Props, State> {
  googleButtonRef = createRef<HTMLDivElement>()
  constructor(props: Props) {
    super(props)

    this.state = {
      suggestions: [],
      searchValue: '',
      logo: '',
      gender: null,
      email: props.email || '',
      emailError: false,
      firstName: props.firstName || '',
      lastName: props.lastName || '',
      password: '',
      confirmPassword: '',
      companyName: props.companyName || '',
      companyDomain: props.companyDomain || '',
      gToken: props.gToken || null,
      passwordMismatch: false,
      passwordTooShort: false,
      genderMissing: false,
      showAlert: false,
      rsEventFired: false,
      successfullyAuthed: false,
      companyWebsite: '',
      checked: false,
      recaptchaError: false,
      recaptchaToken: null,
      refreshReCaptcha: false,
    }
  }

  componentDidMount() {
    const { runTasks, query, loadTeamSimple, loadMe } = this.props
    const teamId = getQueryParam(query.team_id)

    if (getQueryParam(query.tasks)) ls.set(AUTH_QUERY_PARAMS_STORAGE_KEY, query)

    if (localAuthToken()) {
      const storedQuery =
        ls.get<ParsedUrlQuery | null>(AUTH_QUERY_PARAMS_STORAGE_KEY) || {}

      if (!getQueryParam(query.team_id)) {
        ls.remove(AUTH_QUERY_PARAMS_STORAGE_KEY)

        handleTasks(runTasks, storedQuery, [
          'accept_referral',
          'generic_invite',
          'team_invite',
        ])
      }

      loadMe()
    }

    if (query.tasks === 'team_invite' && teamId) {
      loadTeamSimple(teamId)
    }

    if (window.google) {
      this.loadGoogleButtonOnPage()
    } else {
      const script = document.querySelector('#gsiScript')
      script?.addEventListener('load', this.loadGoogleButtonOnPage)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { registrationState, runTasks, loadMe, authUser, meState, query } =
      this.props
    const { successfullyAuthed, email, gToken } = this.state
    const justLoadedMe =
      prevProps.meState !== 'success' && meState === 'success'
    const regState = registrationState.state
    const justFoundDeactivated =
      prevProps.registrationState.state !== 'failure' &&
      registrationState.state == 'failure' &&
      registrationState.message == 'Email is deactivated'
    const justFailedAuth =
      prevProps.registrationState.state !== 'failure' &&
      registrationState.state == 'failure'
    const justRegistered =
      prevProps.registrationState.state !== regState && regState === 'success'
    const teamInvite = query.tasks === 'team_invite'

    if (justRegistered) {
      const storedQuery = ls.get<ParsedUrlQuery | null>(
        AUTH_QUERY_PARAMS_STORAGE_KEY,
      )
      ls.remove(AUTH_QUERY_PARAMS_STORAGE_KEY)
      this.setState({ successfullyAuthed: true })

      if (storedQuery) {
        handleTasks(runTasks, storedQuery)
      }

      loadMe()
    }

    if (justFailedAuth) rsEvent('Signup Failed', { email })

    if (justLoadedMe && authUser) {
      rsIdentify(
        authUser.id,
        {
          profile_id: authUser.id,
          email: authUser.email,
          firstName: authUser.first_name,
          lastName: authUser.last_name,
          created_at: authUser.created_at,
          hubspot_organization_id: authUser.team.hubspot_organization_id,
          invited_by_expert: getQueryParam(query.tasks)?.includes(
            'accept_referral',
          )
            ? 'true'
            : 'false',
        },
        { groupId: authUser.team.hubspot_organization_id },
      )

      if (successfullyAuthed) {
        rsEvent('Signup Successful', {
          login_type: gToken ? 'google' : 'manual',
        })
        this.handleRedirect()
      } else {
        // If user is going through a Team Invite and is registered
        if (teamInvite) {
          Router.push('/app')
        } else {
          Router.push(`/app${this.queryParams()}`)
        }
      }
    }

    this.handleCompany()

    if (justFoundDeactivated) this.setState({ showAlert: true })
  }

  handleRecaptchaVerify = (token: string) => {
    this.setState({ recaptchaToken: token })
  }

  render() {
    return (
      <div>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
        >
          {this.renderDeepLinkFrame()}
          <Header imgBackground>
            <div className={classNames('container')}>
              {this.renderHeader()}
              {this.renderContent()}
              {this.renderAlert()}
              {this.renderBottomContent()}
            </div>
          </Header>
        </GoogleReCaptchaProvider>
      </div>
    )
  }

  renderGoogleLoginButton() {
    return (
      <div className={classNames('socialSignInContainer')}>
        <div className={classNames('or')}>or</div>
        <div id="google_signin" ref={this.googleButtonRef} />
      </div>
    )
  }

  renderDeepLinkFrame() {
    const { profileId } = this.props

    if (profileId) {
      return <DeepLinkFrame src={`crystalapp://profile?id=${profileId}`} />
    }
  }

  renderHeader() {
    const { publicAssessment } = this.props

    if (publicAssessment) {
      return (
        <h1 className={classNames('title')}>
          Create <strong>free account</strong> to see your full personality
          profile
        </h1>
      )
    }
  }

  renderTerms() {
    return (
      <div className={classNames('checkbox')}>
        <div className={classNames('input')}>
          <Checkbox
            noMargin
            onChange={checked => {
              this.setState({ checked })
            }}
            checked={this.state.checked}
            required
          />
        </div>
        <span>
          I agree to Crystal's{' '}
          <a
            href="https://crystalknows.com/tos"
            target="_blank"
            rel="noreferrer"
          >
            Terms and Conditions
          </a>
        </span>
      </div>
    )
  }

  renderContent() {
    const { uiState, teamSimpleResult } = this.props
    const loading = uiState == 'loading'

    if (teamSimpleResult) {
      return (
        <div>
          {this.renderInviteCTA()}
          <form
            onSubmit={this._handleSubmit}
            id="form"
            className={classNames('')}
          >
            <GoogleReCaptcha
              onVerify={this.handleRecaptchaVerify}
              refreshReCaptcha={this.state.refreshReCaptcha}
            />
            <div className={classNames('top')}>
              {this.renderRecaptchaError()}
              {this.renderHeadingForInviteMode()}
              {this.renderNameInput()}
              {this.renderEmailInput()}
              {this.renderPasswordInput()}
              {this.renderTerms()}
              <div className={classNames('buttonContainer', { loading })}>
                <Button loading={loading} buttonTag type="submit">
                  Sign Up
                </Button>
              </div>
            </div>
          </form>
        </div>
      )
    }

    return (
      <div>
        {this.renderInviteCTA()}

        <div className={classNames('twocol-container')}>
          <div className={classNames('column', 'left')}>
            <div className={classNames('top')}>
              <div className={classNames('left-header')}>
                Personality-based guidance to...
              </div>
              <OnboardingCarousel />
            </div>
          </div>
          <div className={classNames('column', 'divider')}></div>
          <div className={classNames('column', 'right')}>
            <form
              onSubmit={this._handleSubmit}
              id="form"
              className={classNames('')}
            >
              <GoogleReCaptcha
                onVerify={this.handleRecaptchaVerify}
                refreshReCaptcha={this.state.refreshReCaptcha}
              />
              <div className={classNames('top')}>
                {this.renderRecaptchaError()}
                {this.renderHeadingForInviteMode()}
                {this.renderNameInput()}
                {this.renderEmailInput()}
                {this.renderPasswordInput()}
                {this.renderTerms()}
                <div className={classNames('buttonContainer', { loading })}>
                  <Button loading={loading} buttonTag type="submit">
                    Sign Up
                  </Button>
                </div>

                {this.renderBottom()}
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  renderRecaptchaError() {
    const { recaptchaError } = this.state
    return (
      <div className={classNames('recaptcha-error')}>
        {recaptchaError ? 'ReCAPTCHA has encountered an error.' : ''}
      </div>
    )
  }

  renderHeadingForInviteMode() {
    return (
      <div>
        <h3 className={classNames('auth__title')}>
          Create your Crystal account
        </h3>
        <h4 className={classNames('auth__subtitle')}>
          In a few minutes, you'll have access to personality profiles,
          personalized communication insights, and the world's leading adaptive
          selling platform.
        </h4>
      </div>
    )
  }

  renderInviteCTA() {
    const { teamSimpleResult, teamSimpleState } = this.props

    if (teamSimpleState === 'loading') {
      return <LoadingBox text="Loading invitation" />
    } else if (teamSimpleResult) {
      return <RegisterCallToAction teamSimple={teamSimpleResult} />
    }
    return null
  }

  renderLoginLink() {
    return (
      <div className={classNames('link')}>
        <span className={classNames('linktext')}>
          Already have an account?{' '}
        </span>
        <Link
          href={`/app/login${this.queryParams()}`}
          className={classNames('link-forgot-color')}
        >
          Sign In
        </Link>
      </div>
    )
  }

  renderEmailInput() {
    const { message, parameter, state } = this.props.registrationState
    const { teamSimpleResult } = this.props
    const { emailError } = this.state
    let error = state == 'failure' && parameter == 'email' ? message[0] : null
    if (emailError) error = 'Please enter a valid email address'

    if (teamSimpleResult) {
      return (
        <InputGroup short>
          <Input
            label="Company Email"
            required={true}
            value={this.state.email}
            onChange={this.handleFormChange('email')}
            error={error}
          />
        </InputGroup>
      )
    }

    return (
      <InputGroup short>
        <Input
          label="Company Email"
          required={true}
          value={this.state.email}
          onChange={this.handleFormChange('email')}
          error={error}
        />
      </InputGroup>
    )
  }

  renderEmailInviteInput() {
    const { message, parameter, state } = this.props.registrationState
    const error = state == 'failure' && parameter == 'email' ? message[0] : null

    return (
      <InputGroup short>
        <Input
          label="Company Email"
          required={true}
          value={this.state.email}
          onChange={this.handleFormChange('email')}
          error={error}
        />
      </InputGroup>
    )
  }

  renderNameInput() {
    const { message, parameter, state } = this.props.registrationState
    const firstNameError =
      state == 'failure' && parameter == 'first_name' ? message[0] : null
    const lastNameError =
      state == 'failure' && parameter == 'last_name' ? message[0] : null

    return (
      <InputGroup short>
        <Input
          label="First Name"
          required={true}
          value={this.state.firstName}
          onChange={this.handleFormChange('firstName')}
          error={firstNameError}
        />
        <Input
          label="Last Name"
          required={true}
          value={this.state.lastName}
          onChange={this.handleFormChange('lastName')}
          error={lastNameError}
          borderLeft
        />
      </InputGroup>
    )
  }

  renderGenderInputWrapper() {
    const genderError = this.state.genderMissing
      ? 'Gender is required'
      : undefined

    const genderOptions = [
      {
        value: 'prefer_not_say',
        label: 'Prefer not to say',
      },
      {
        value: 'male',
        label: 'Male',
      },
      {
        value: 'female',
        label: 'Female',
      },
    ]

    return (
      <Dropdown
        placeholder="Gender"
        searchable
        smallFont
        height={68}
        value={this.state.gender}
        options={genderOptions}
        onChange={this._handleGenderChange}
        error={genderError}
        flat
      />
    )
  }

  renderPasswordInput() {
    const { passwordMismatch, passwordTooShort } = this.state
    const { message, parameter, state } = this.props.registrationState

    let error = ''
    if (passwordMismatch) error = 'Passwords do not match'
    if (passwordTooShort) error = 'Must be 8 or more characters'
    else if (state == 'failure' && parameter == 'encrypted_password')
      error = message[0]

    return (
      <InputGroup short>
        <Input
          label="Password"
          type="password"
          required={true}
          value={this.state.password}
          onChange={this.handleFormChange('password')}
        />
        <Input
          label="Confirm Password"
          type="password"
          required={true}
          value={this.state.confirmPassword}
          onChange={this.handleFormChange('confirmPassword')}
          error={error}
          borderLeft
        />
      </InputGroup>
    )
  }

  renderAlert() {
    const { email, showAlert } = this.state

    if (showAlert) {
      const queryParams = this.queryParams()
      const extraParams = queryParams ? `&${queryParams.slice(1)}` : ''

      return (
        <Alert
          title="Email address already in use"
          description="This account has been deactivated. To reactivate it, go to the login screen and enter your credentials. Logging in will automatically reactivate your account."
          confirmAction={() =>
            Router.push(`/app/login?email=${email}${extraParams}`)
          }
          confirmText="Go to login"
          backAction={() => this.setState({ showAlert: false })}
        />
      )
    }
  }

  renderBottomContent() {
    const { showMarketing } = this.props

    if (!showMarketing) return null

    return (
      <div className={classNames('marketing-content-wrapper')}>
        <div className={classNames('marketing-content')}>
          <div className={classNames('companies')}>
            <div className={classNames('subtext')}>As featured in</div>
            <img src={fortune} />
            <img src={cnn} />
            <img src={wsj} />
            <img src={huffpost} />
            <img src={guardian} />
          </div>

          <MarketingSection
            title="See anyone’s personality"
            body="Crystal can tell you anyone’s personality by analyzing their social media profile or CRM record. "
            image="https://www.crystalknows.com/hubfs/WebsiteApril2019/site-previews/li-preview.png"
          />
          <MarketingSection
            reverse
            title="Your coach for every conversation"
            body="Get personality-based advice for any meeting, phone call, or email."
            image="https://www.crystalknows.com/hubfs/WebsiteApril2019/site-previews/coach-profile-preview.png"
          />
          <MarketingSection
            title="Take a free personality assessment"
            body="Invite anyone you know, like co-workers and friends, to take a free personality test and improve your communication."
            image="https://cdn.crystalknows.com/images/elon-profile.png"
          />
        </div>
      </div>
    )
  }

  renderBottom() {
    return (
      <div className={classNames('bottom')}>
        <input className={classNames('hidden')} type="submit" value="Submit" />
        {this.renderGoogleLoginButton()}
        {this.renderLoginLink()}
      </div>
    )
  }

  // Functions
  handleFormChange =
    (
      stateKey:
        | 'email'
        | 'firstName'
        | 'lastName'
        | 'password'
        | 'confirmPassword'
        | 'companyWebsite',
    ) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const { rsEventFired } = this.state

      if (!rsEventFired) {
        this.setState({ rsEventFired: true }, () => {
          rsEvent('Signup Started')
        })
      }

      this.setState({ [stateKey]: value } as Pick<State, typeof stateKey>)
    }

  _handleGenderChange = (option: DropdownOption) => {
    if (option && option.value) this.setState({ gender: option.value })
  }

  _handleCompanySelect = (event: DropdownOption) => {
    if (event && event.value) this.setState({ companyName: event.value })
  }

  _handleSubmit = async (event?: React.SyntheticEvent) => {
    if (event) event.preventDefault()

    const { uiState } = this.props

    const { checked } = this.state

    if (uiState == 'loading' || this._password_invalid() || !checked) {
      return null
    }
    if (!this.state.recaptchaToken) {
      this.setState({ recaptchaError: true })
      return
    }
    const emailString = this.state.email
    const emailIsValid = validateEmail(emailString)
    if (!emailIsValid) {
      this.setState({ emailError: true })
      return
    }
    this.register(this.state.recaptchaToken)
    this.setState(prevState => ({
      refreshReCaptcha: !prevState.refreshReCaptcha,
    }))
  }

  register(captchaResponse: string) {
    const {
      companyInviteToken,
      registerAttributes,
      profileId,
      inviteToken,
      actions,
      query,
    } = this.props
    const {
      email,
      password,
      firstName,
      lastName,
      companyName,
      companyDomain,
      gToken,
      companyWebsite,
    } = this.state

    const source = companyName === '' && companyDomain === '' ? '' : 'clearbit'
    const segment = getQueryParam(query.segment)
    const compDomain =
      companyName == 'no_company' ? '' : this._getCompanyDomain()
    const compName = companyName == 'no_company' ? '' : companyName

    const attributes: RegistrationParams = {
      email: email,
      password,
      firstName,
      lastName,
      companyName: compName,
      companyDomain: compDomain,
      source,
      companyInviteToken,
      gToken: gToken || undefined,
      companyWebsite,
      captchaResponse: captchaResponse,
    }

    if (profileId) attributes.profileId = profileId
    if (actions) attributes.actions = actions
    if (inviteToken) attributes.inviteToken = inviteToken
    if (segment && ['leadership', 'sales', 'hiring'].includes(segment))
      attributes.current_segment = segment as SegmentTypes
    if (query.team_id) attributes.team_id = getQueryParam(query.team_id)
    if (query.team_unit_id)
      attributes.team_unit_id = getQueryParam(query.team_unit_id)
    registerAttributes(attributes)
  }

  _password_invalid = () => {
    const { password, confirmPassword, gToken } = this.state

    if (gToken) return false

    if (password !== confirmPassword) {
      this.setState({ passwordMismatch: true, passwordTooShort: false })
      return true
    } else if (password.length < 8) {
      this.setState({ passwordTooShort: true, passwordMismatch: false })
      return true
    }

    this.setState({ passwordTooShort: false, passwordMismatch: false })
    return false
  }

  _gender_missing = () => {
    const { gender, gToken } = this.state

    if (gToken) return false

    if (gender === null) {
      this.setState({ genderMissing: true })
      return true
    }

    this.setState({ genderMissing: false })
    return false
  }

  handleRedirect = () => {
    const { afterLoginPath, query } = this.props

    let path = '/app/onboarding?registered=true'

    if (afterLoginPath && afterLoginPath.match(/.*roles.*survey/)) {
      ls.remove('afterLoginPath')
      ls.remove('pubAssessFinished')
      path = afterLoginPath
    } else if (query.segment == 'leadership') path += '&step=leadership'

    if (getQueryParam(query.tasks)?.includes('team_invite'))
      path += '&team_invite=true'

    return location.assign(path)
  }

  _getCompanyDomain = () => {
    const { email } = this.state
    return email.split('@')[1]
  }

  _loadCompanySuggestion = () => {
    const { suggestCompany } = this.props
    const domain = this._getCompanyDomain()

    suggestCompany(domain)
  }

  _hasCompanyResults = () => {
    const { suggestStates } = this.props
    const domain = this._getCompanyDomain()

    return suggestStates[domain] == 'success'
  }

  handleCompany = () => {
    const { suggestResults } = this.props
    const { companyName } = this.state
    const domain = this._getCompanyDomain()
    const suggestion = suggestResults[domain]

    if (suggestion && companyName.length == 0 && suggestion.name) {
      this.setState({ companyName: suggestion.name })
    }
  }

  _getCompanySuggestions = () => {
    const { suggestResults } = this.props
    const domain = this._getCompanyDomain()
    const defaultOption = { label: 'No Company', value: 'no_company' }
    const suggestion = suggestResults[domain]

    if (suggestion)
      return [this._formatSuggestion(suggestion.name), defaultOption]

    return [defaultOption]
  }

  _formatSuggestion = (companyName: string | null) => {
    return { label: companyName || '', value: companyName }
  }

  queryParams() {
    const { asPath } = this.props
    const match = asPath.match(/(\?.*)/)

    return match && match[1] ? match[1] : ''
  }

  loadGoogleButtonOnPage = () => {
    window.google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: this.handleCredentialResponse,
    })
    if (this.googleButtonRef.current) {
      window.google.accounts.id.renderButton(this.googleButtonRef.current, {
        theme: 'outline',
        size: 'large',
      })
    }
  }

  handleCredentialResponse = (response: {
    clientId: string
    credential: string
    select_by: string
  }) => {
    const decoded = jwtDecode<GoogleCredentialType>(response.credential)
    this.setState(
      {
        gToken: response.credential,
        email: decoded.email,
        firstName: decoded.given_name,
        lastName: decoded.family_name,
      },
      () => {
        this._handleSubmit()
      },
    )
  }
}
