import React, { CSSProperties, FC } from 'react'

import styles from './FadeIn.module.scss'

interface OwnProps {
  sequential?: boolean
  styleObject?: CSSProperties
  children: React.ReactNode
  customClassName?: string
}

const FadeIn: FC<OwnProps> = ({
  sequential,
  styleObject,
  children,
  customClassName = '',
}: OwnProps) => {
  return (
    <div
      style={styleObject}
      className={`${styles.container} ${
        sequential ? styles.sequential : ''
      } ${customClassName}`}
    >
      {children}
    </div>
  )
}

export default FadeIn
