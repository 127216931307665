import React, { PropsWithChildren } from 'react'
import classNamesBind from 'classnames/bind'
import classNames from 'classnames'
import Container from '@dashboard/components/Container'

import css from '../styles.module.scss'
const cx = classNamesBind.bind(css)

const backgroundImg = '/static/share/images/background.svg'

interface OwnProps {
  img?: string
  centerSmall?: boolean
  imgBackground?: boolean
  flexFill?: boolean
  uncontained?: boolean
  noMobilePadding?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  customClass?: string
}

const Header: React.FC<PropsWithChildren<OwnProps>> = ({
  children,
  centerSmall,
  flexFill,
  uncontained,
  noMobilePadding,
  style,
  imgBackground,
  customClass,
}) => {
  const renderImgBackground = () => {
    if (!imgBackground) return null

    return (
      <div className={cx('add-people-background')}>
        <img src={backgroundImg} className={cx('add-people-background-img')} />
      </div>
    )
  }

  return (
    <header
      className={classNames(
        cx('wrapper', {
          'center-small': centerSmall,
          noMobilePadding,
        }),
        customClass,
      )}
      style={style}
    >
      {renderImgBackground()}
      <Container uncontained={uncontained} flexFill={flexFill}>
        {children}
      </Container>
    </header>
  )
}

interface HeaderSubcomponents {
  Body: React.ElementType
  Action: React.ElementType
  TitleSection: React.ElementType
  Title: React.ElementType
  Subtitle: React.ElementType
  Image: React.ElementType
}

const HeaderWithSubcomponents = Header as React.FC<
  PropsWithChildren<OwnProps>
> &
  HeaderSubcomponents

export default HeaderWithSubcomponents
