import React, { FC, PropsWithChildren } from 'react'
import classNamesBind from 'classnames/bind'
import css from '../styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  img?: string
}

const Image: FC<PropsWithChildren<OwnProps>> = ({ img }) => {
  const handleImageError = (
    evt: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    const imageEl = evt.target as HTMLImageElement
    imageEl.src = '/static/share/images/company.svg'
  }
  return (
    <img onError={handleImageError} src={img} className={classNames('img')} />
  )
}

export default Image
