import React, { FC, PropsWithChildren } from 'react'
import classNamesBind from 'classnames/bind'
import classNames from 'classnames'
import css from '../styles.module.scss'
const cx = classNamesBind.bind(css)

interface OwnProps {
  time?: string
  style?: React.CSSProperties
  customClass?: string
}

const Title: FC<PropsWithChildren<OwnProps>> = ({
  time,
  children,
  customClass,
}) => {
  return (
    <h2 className={classNames(cx('title'), customClass)}>
      {children}
      {time && <span className={cx('time')}>{time}</span>}
    </h2>
  )
}

export default Title
