import React, { FC, PropsWithChildren } from 'react'
import classNamesBind from 'classnames/bind'
import css from '../styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  center?: boolean
  className?: string
}

const Body: FC<PropsWithChildren<OwnProps>> = ({
  children,
  center,
  className,
}) => {
  return (
    <div className={classNames('top', { center }, className)}>{children}</div>
  )
}

export default Body
