import Header from './components/Header'
import Body from './components/Body'
import Action from './components/Action'
import TitleSection from './components/TitleSection'
import Title from './components/Title'
import Subtitle from './components/Subtitle'
import Image from './components/Image'

Header.Body = Body
Header.Action = Action
Header.TitleSection = TitleSection
Header.Title = Title
Header.Subtitle = Subtitle
Header.Image = Image

export default Header
