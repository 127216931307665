import React, { FC, PropsWithChildren } from 'react'
import Header from '.'
import Title from './components/Title'
import Subtitle from './components/Subtitle'
import Action from './components/Action'
import TitleSection from './components/TitleSection'
import Body from './components/Body'
import HeaderImage from './components/Image'

const applyStylesBasedOnProps = props => {
  const styleMappings: { [key: string]: () => React.CSSProperties } = {
    heavyPadded: () => ({ padding: '120px 0' }),
    padBottom: () => ({ paddingBottom: '120px' }),
    noBottomPadding: () => ({ paddingBottom: '0' }),
    padded: () => ({ padding: '60px 0' }),
    flexFill: () => ({ flex: '1', display: 'flex', flexDirection: 'column' }),
    white: () => ({ backgroundColor: '#fff' }),
    centerContent: () => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }),
    grey: () => ({ backgroundColor: '#f5f5f5' }),
    overlap: () => ({ paddingTop: '48px', paddingBottom: '236px' }),
    noPadding: () => ({ padding: '0' }),
    transparent: () => ({ background: 'none' }),
  }

  const styleObject = {}

  Object.keys(props).forEach(prop => {
    if (props[prop] && styleMappings[prop]) {
      Object.assign(styleObject, styleMappings[prop]())
    }
  })

  return styleObject
}

interface OwnProps {
  title?: string
  subtitle?: string
  action?: () => void
  actionText?: string
  to?: string
  time?: string
  center?: boolean
  img?: string
  noBottomPadding?: boolean
  padBottom?: boolean
  heavyPadded?: boolean
  padded?: boolean
  white?: boolean
  grey?: boolean
  centerSmall?: boolean
  imgBackground?: boolean
  flexFill?: boolean
  centerContent?: boolean
  uncontained?: boolean
  noMobilePadding?: boolean
  overlap?: boolean
  noPadding?: boolean
  transparent?: boolean
}

const HeaderLegacy: FC<PropsWithChildren<OwnProps>> = props => {
  const {
    children,
    title,
    time,
    subtitle,
    center,
    action,
    to,
    actionText,
    img,
    centerSmall,
    imgBackground,
    flexFill,
    uncontained,
    noMobilePadding,
  } = props
  const styles = applyStylesBasedOnProps(props)

  return (
    <Header
      img={img}
      centerSmall={centerSmall}
      imgBackground={imgBackground}
      flexFill={flexFill}
      uncontained={uncontained}
      noMobilePadding={noMobilePadding}
      style={styles}
    >
      <Body center={center}>
        {img && <HeaderImage img={img} />}
        <TitleSection>
          <>
            {title && <Title time={time}>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </>
        </TitleSection>
        {(action || to) && actionText && (
          <Action action={action} to={to}>
            {actionText}
          </Action>
        )}
      </Body>
      {children}
    </Header>
  )
}

export default HeaderLegacy
