import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  src: string
}

const DeepLinkFrame: FC<OwnProps> = ({ src }: OwnProps) => {
  return <iframe className={classNames('container')} src={src} />
}

export default DeepLinkFrame
