import React, { FC, ReactNode } from 'react'
import classNamesBind from 'classnames/bind'

import Loader from '@crystal-eyes/components/elements/Loader/Loader'
import Tooltip from '@dashboard/components/Tooltip'

interface OwnProps {
  checked?: boolean
  label?: ReactNode
  size?: number
  noHover?: boolean
  loading?: boolean
  noMargin?: boolean
  bigLabel?: boolean
  tooltip?: string
  required?: boolean
  onChange?: (checked: boolean) => void
}

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

const Checkbox: FC<OwnProps> = ({
  checked,
  onChange,
  noHover,
  loading,
  size,
  noMargin,
  bigLabel,
  label,
  tooltip,
  required,
}: OwnProps) => {
  const style = {
    width: `${size || 18}px`,
    height: `${size || 18}px`,
    minWidth: `${size || 18}px`,
    minHeight: `${size || 18}px`,
  }

  // Partials
  const renderCheck = () => {
    if (loading) return <Loader blue size={size || 18} />

    return <img src="/static/share/images/check-white.svg" />
  }

  const renderLabel = () => {
    if (!label) return null

    return (
      <div className={classNames('label', { bigLabel, noMargin })}>{label}</div>
    )
  }

  const renderTooltip = () => {
    if (!tooltip) return null

    return (
      <div className={classNames('tooltip')}>
        <Tooltip inline text={tooltip} />
      </div>
    )
  }

  return (
    <label
      className={classNames('container', { hoverState: !noHover, noMargin })}
    >
      <input
        className={classNames('input')}
        type="checkbox"
        checked={checked}
        onChange={() => onChange && onChange(!checked)}
        required={required}
      />
      <div
        style={style}
        className={classNames('checkbox', { checked, noMargin, loading })}
      >
        {renderCheck()}
      </div>
      {renderLabel()}
      {renderTooltip()}
    </label>
  )
}

export default Checkbox
