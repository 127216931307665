import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import ProfileImage from '@dashboard/components/ProfileImage'
import { TeamSimple, SimpleInviteProfile } from '@dashboard/lib/types'

interface OwnProps {
  teamSimple: TeamSimple
}

import css from './styles.module.scss'
import { fullName } from '@dashboard/lib/profile'
const classNames = classNamesBind.bind(css)

const CallToActionBubble: FC<OwnProps> = ({ teamSimple }: OwnProps) => {
  const renderProfileSegment = (member: SimpleInviteProfile) => {
    return (
      <li key={member.id} className={classNames('member')}>
        <div className={classNames('inner')}>
          <div className={classNames('photo')}>
            <ProfileImage
              size={26}
              photoUrl={member.photo_url}
              fullName={fullName(member)}
            />
          </div>

          <div className={classNames('profile-wrapper')}>
            <h4 className={classNames('name')}>
              {member.first_name} {member.last_name}
            </h4>
            <p className={classNames('job-title')}>
              {member.job_title || 'Teammate'}
            </p>
          </div>
        </div>
      </li>
    )
  }

  return (
    <div className={classNames('container')}>
      <div className={classNames('heading')}>
        Some of your ({teamSimple.count}) teammates currently on Crystal:
      </div>
      <ul className={classNames('member-wrapper')}>
        {teamSimple.members.map(renderProfileSegment)}
      </ul>
    </div>
  )
}

export default CallToActionBubble
