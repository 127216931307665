import React, { FC, PropsWithChildren } from 'react'
import classNamesBind from 'classnames/bind'
import css from '../styles.module.scss'
const classNames = classNamesBind.bind(css)

const TitleSection: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return <div className={classNames('titles')}>{children}</div>
}

export default TitleSection
