import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import CallToActionBubble from '@dashboard/components/CallToActionBubble'
import { TeamSimple } from '@dashboard/lib/types'

interface OwnProps {
  teamSimple: TeamSimple
}

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

const RegisterCallToAction: FC<OwnProps> = ({ teamSimple }: OwnProps) => {
  return (
    <div className={classNames('container')}>
      <div className={classNames('top')}>
        <h1 className={classNames('heading')}>
          Join {teamSimple.name} on Crystal!
        </h1>
        <h2 className={classNames('subheading')}>
          Crystal uses AI to help you personalize your pitch, book more
          meetings, and win more business.
        </h2>
        <CallToActionBubble teamSimple={teamSimple} />
      </div>
    </div>
  )
}

export default RegisterCallToAction
