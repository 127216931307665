import React, { FC, PropsWithChildren } from 'react'
import classNamesBind from 'classnames/bind'
import css from '../styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {
  className?: string
}

const Subtitle: FC<PropsWithChildren<OwnProps>> = ({ children, className }) => {
  return children ? (
    <p className={classNames('subtitle', className)}>{children}</p>
  ) : null
}

export default Subtitle
