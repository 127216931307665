import React, { FC, useState } from 'react'
import classNamesBind from 'classnames/bind'

import FadeIn from '@crystal-eyes/components/elements/FadeIn'
import Buttons from '@dashboard/components/Buttons'
import css from './styles.module.scss'
import ActionButton from '@crystal-eyes/components/elements/Button/ActionButton'

interface OwnProps {
  title: string
  description?: string
  confirmAction: () => void
  backAction?: () => void
  confirmText?: string
}

const classNames = classNamesBind.bind(css)

const Alert: FC<OwnProps> = ({
  description,
  backAction,
  confirmText,
  confirmAction,
  title,
}: OwnProps) => {
  const [loading, setLoading] = useState(false)

  // Functions
  const handleClick = () => {
    setLoading(true)
    confirmAction()
  }

  // Partials
  const renderDescription = () => {
    if (!description) return null

    return <p className={classNames('description')}>{description}</p>
  }

  const renderCloseIcon = () => {
    if (!backAction) return null

    return (
      <img
        className={classNames('close-icon')}
        src="/static/share/images/close.svg"
        onClick={backAction}
      />
    )
  }

  const renderConfirm = () => {
    return (
      <ActionButton loading={loading} onClick={handleClick}>
        {confirmText}
      </ActionButton>
    )
  }

  return (
    <div className={classNames('wrapper')}>
      <FadeIn>
        <div className={classNames('container')}>
          {renderCloseIcon()}
          <h2 className={classNames('title')}>{title}</h2>
          {renderDescription()}
          <Buttons>{renderConfirm()}</Buttons>
        </div>
      </FadeIn>
    </div>
  )
}

export default Alert
